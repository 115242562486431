<template lang="pug">
  v-container
    v-snackbar(
      color="green"
      bottom
      v-model="successTransfer"
      :min-width="$vuetify.breakpoint.width < 800 ? '90vw': null"
    )
      v-icon mdi-check
      span Rack Successfully transfered
    v-row(justify="center")
      v-col(lg="3" md="5" cols="12" v-if="restrictedAccess(6)")
        create-incubator(
          :default-data.sync="editIncubator"
        )
      v-col(lg="7" md="7" cols="12")
        table-incubator(
          :edit.sync="editIncubator"
          @transfer="item => incubatorRack = item"
        )
      transfer-eggs(
        v-model="incubatorRack"
        @success="successTransfer = true"
      )
</template>
<script>
export default {
  name: 'ManageIncubator',
  components: {
    createIncubator: () => import('./Create'),
    tableIncubator: () => import('./Table'),
    transferEggs: () => import('./Transfer'),
  },
  data () {
    return {
      editIncubator: {},
      incubatorRack: {},
      successTransfer: false,
    }
  },
  methods: {
    restrictedAccess (level) {
      return this.$store.state.auth.accessLevel >= level
    },
  },
}
</script>